<template>
 <Main class="grid-boxed mt-10">
  <a-row :gutter="25">
   <a-col :lg="8" :xs="24">
    <sdCards headless>
     <OverviewSalesCard>
      <div class="icon-box box-primary">
       <SvgConverter :src="require('@/static/img/icon/New Customer.svg')"/>
      </div>
      <div class="card-chunk">
       <CardBarChart2>
        <h2>{{ new Intl.NumberFormat('nl-NL').format(counts.total.relations) }}</h2>
        <span>Totaal aantal relaties</span>
        <p>
         <span class="growth-upward"> <sdFeatherIcons type="arrow-up"/> {{ counts.lastWeek.relations }} </span>
         <span>Sinds deze week</span>
        </p>
       </CardBarChart2>
      </div>
     </OverviewSalesCard>
    </sdCards>

    <sdCards headless>
     <OverviewSalesCard>
      <div class="icon-box box-secondary">
       <SvgConverter :src="require('../../static/img/icon/Opened.svg')"/>
      </div>
      <div class="card-chunk">
       <CardBarChart2>
        <h2>{{ new Intl.NumberFormat('nl-NL').format(counts.total.signedOrders) }}</h2>
        <span>Getekende orders</span>
        <p>
         <span class="growth-upward"> <sdFeatherIcons type="arrow-up"/> {{ counts.lastWeek.signedOrders }} </span>
         <span>Sinds deze week</span>
        </p>
       </CardBarChart2>
      </div>
     </OverviewSalesCard>
    </sdCards>
    <sdCards headless>
     <OverviewSalesCard>
      <div class="icon-box box-success">
       <SvgConverter :src="require('../../static/img/icon/SalesRevenue.svg')"/>
      </div>
      <div class="card-chunk">
       <CardBarChart2>
        <h2>{{ new Intl.NumberFormat('nl-NL').format(counts.total.totalOrders) }}</h2>
        <span>Totaal aantal orders</span>
        <p>
         <span class="growth-upward"> <sdFeatherIcons type="arrow-up"/> {{ counts.lastWeek.totalOrders }} </span>
         <span>Sinds deze week</span>
        </p>
       </CardBarChart2>
      </div>
     </OverviewSalesCard>
    </sdCards>
   </a-col>
   <a-col :lg="16" :xs="24">
    <Suspense>
     <template #default>
      <AverageSalesRevenue :chart="chart" :chartHeights="125"/>
     </template>
     <template #fallback>
      <sdCards headless>
       <a-skeleton active/>
      </sdCards>
     </template>
    </Suspense>
   </a-col>
   <a-col :lg="8" :xs="24">
    <Suspense>
     <template #default>
      <SalesTarget :signConversion="signConversion"/>
     </template>
     <template #fallback>
      <sdCards headless>
       <a-skeleton active/>
      </sdCards>
     </template>
    </Suspense>
   </a-col>
   <a-col :lg="8" :xs="24">
    <Suspense>
     <template v-if="!loadingDefaultActivities">
      <ActivityContents>
       <sdCards title="Recente activiteiten">
        <ul class="activity-list" style="height:330px;overflow-y: scroll">
         <li v-for="(activity, index) in activities" :key="index" class="activity-list__single">
          <span :class="`activity-icon ${getActivityColor(activity).color}`" style="padding:10px">
            <sdFeatherIcons :type="`${getActivityColor(activity).icon}`" size="14"/>
          </span>
          <div class="activity-content">
           <div class="activity-info">
            <p>
             <small>
              <span class="inline-text color-primary"><a :href="`/order/${activity.orderID}`">{{ activity.orderID }}</a></span>
              - {{ activity.description }}
              <span class="hour">{{ moment(timestamp).format('DD-MM-YYYY HH:mm:ss') }}</span>
             </small>
            </p>
           </div>
          </div>
         </li>
        </ul>
       </sdCards>
      </ActivityContents>
     </template>
     <template v-else>
      <sdCards headless>
       <a-skeleton active/>
      </sdCards>
     </template>
    </Suspense>
   </a-col>
   <a-col :lg="8" :xs="24">
    <Suspense>
     <template #default>
      <SalesCancel :cancelConversion="cancelConversion"/>
     </template>
     <template #fallback>
      <sdCards headless>
       <a-skeleton active/>
      </sdCards>
     </template>
    </Suspense>
   </a-col>
   <a-col :lg="12" :xs="24">
    <Suspense>
     <template #default>
      <TopSellingProduct :products="products"/>
     </template>
     <template #fallback>
      <sdCards headless>
       <a-skeleton active/>
      </sdCards>
     </template>
    </Suspense>
   </a-col>
   <a-col :lg="12" :xs="24">
    <Suspense>
     <template #default>
      <RecentOrder :orders="orders"/>
     </template>
     <template #fallback>
      <sdCards headless>
       <a-skeleton active/>
      </sdCards>
     </template>
    </Suspense>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {CardBarChart2, OverviewSalesCard} from './style';
import {Main} from '../styled';
import {computed, defineAsyncComponent, onMounted} from 'vue';
import SvgConverter from '@/components/utilities/SvgConverter.vue';
import {useStore} from "vuex";
import {ActivityContents} from "@/view/orders/component/style";
import moment from "moment";

const AverageSalesRevenue = defineAsyncComponent(() => import('./overview/sales/AverageSalesRevenue'));
const SalesGrowth = defineAsyncComponent(() => import('./overview/sales/SalesGrowth'));
const SalesTarget = defineAsyncComponent(() => import('./overview/sales/SalesTarget'));
const TopSellingProduct = defineAsyncComponent(() => import('./overview/sales/TopSellingProduct'));
const TopCountriesRevenue = defineAsyncComponent(() => import('./overview/sales/TopCountriesRevenue'));
const RecentOrder = defineAsyncComponent(() => import('./overview/sales/RecentOrder'));
const SalesCancel = defineAsyncComponent(() => import('./overview/sales/SalesCancel'));

const Sales = {
 name: 'Sales',
 components: {
  Main,
  CardBarChart2,
  OverviewSalesCard,
  AverageSalesRevenue,
  SalesTarget,
  TopSellingProduct,
  SalesGrowth,
  RecentOrder,
  TopCountriesRevenue,
  SalesCancel,
  SvgConverter,
  ActivityContents,
 },
 setup() {
  const {state, dispatch} = useStore();
  const counts = computed(() => state.dashboard.counts);
  const orders = computed(() => state.dashboard.orders);
  const products = computed(() => state.dashboard.products);
  const chart = computed(() => state.dashboard.chart);
  const monthlySaleCounts = computed(() => state.dashboard.monthlySaleCounts);
  const signConversion = computed(() => state.dashboard.signConversion);
  const cancelConversion = computed(() => state.dashboard.cancelConversion);
  const activities = computed(() => state.dashboard.activities);
  const loadingDefaultActivities = computed(() => state.dashboard.loadingDefaultActivities);

  onMounted(() => {
   dispatch('fetchDefaultDashboardChart');
   dispatch('fetchDefaultDashboardCounts');
   dispatch('fetchDefaultDashboardOrders');
   dispatch('fetchDefaultDashboardProducts');
   dispatch('fetchDefaultDashboardSignConversion');
   dispatch('fetchDefaultDashboardCancelConversion');
   dispatch('fetchDefaultDashboardMonthlySaleCounts');
   dispatch('fetchDefaultDashboardActivities');
  });

  const getActivityColor = (activity) => {
   if (activity.type === 'INFORMATIONAL') {
    if (activity.description.indexOf('ondertekend') > -1) return {color: 'success', icon: 'check'}
    return {color: 'info', icon: 'info'};
   }

   if (activity.type === 'STATUSCHANGE') {
    if (activity.newStatus === 'CANCELLED') return {color: 'danger', icon: 'x-circle'}
    if (activity.newStatus === 'ERROR') return {color: 'warning', icon: 'info'}
    if (activity.newStatus === 'EXPIRED') return {color: 'warning', icon: 'clock'}
    if (activity.newStatus === 'FINISHED') return {color: 'success', icon: 'award'}
    if (activity.newStatus === 'NEW') return {color: 'primary', icon: 'clock'}
    if (activity.newStatus === 'PROCESSED') return {color: 'primary', icon: 'check'}
    if (activity.newStatus === 'SENT') return {color: 'primary', icon: 'send'}
   }

   return 'primary';
  }

  return {
   counts,
   orders,
   products,
   chart,
   monthlySaleCounts,
   signConversion,
   cancelConversion,
   activities,
   getActivityColor,
   loadingDefaultActivities,
   moment,
  }
 }
};

export default Sales;
</script>
<style>
::-webkit-scrollbar {
 -webkit-appearance: none;
 width: 7px;
}

::-webkit-scrollbar-thumb {
 border-radius: 4px;
 background-color: rgba(0, 0, 0, .5);
 box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>
